// App imports
import './styles.scss';

export const Partners = () => {
	return (
		<div className="partners-wrapper">
			<div className="partners-item">
				<a 
					href="https://www.ospa.place/"
					style={{display: "table-cell"}}
					target="_blank"
					rel="noopener noreferrer"
					className="partners-image"
				>
					<svg 
						preserveAspectRatio="xMidYMid meet" 
						data-bbox="0 0 68 20" 
						viewBox="0 0 68 20" 
						height="20" 
						width="68" 
						data-type="color" 
						role="img" 
						aria-label="Place"
					>
					    <g>
					        <path fill="#1E1E1E" d="M7.82 0H0v19.83h4.116v-6.657H7.82c1.372 0 2.579-.255 3.621-.793 1.043-.539 1.866-1.304 2.442-2.295.577-.992.878-2.153.878-3.484 0-1.304-.301-2.465-.878-3.485-.576-.991-1.399-1.756-2.441-2.294C10.399.283 9.191 0 7.82 0ZM4.116 9.632v-6.09h3.32c.96 0 1.728.282 2.304.82.55.567.85 1.304.85 2.239 0 .934-.3 1.671-.85 2.21-.576.566-1.344.82-2.304.82h-3.32Z" data-color="1"></path>
					        <path fill="#1E1E1E" d="M16.766 0v19.83h3.869V0h-3.869Z" data-color="1"></path>
					        <path fill="#1E1E1E" d="M37.214 16.459h-.439c-.329 0-.548-.057-.686-.198-.164-.114-.22-.369-.22-.737v-5.127c0-1.7-.548-3.032-1.645-3.938-1.098-.907-2.634-1.36-4.61-1.36-1.92 0-3.43.425-4.582 1.247-1.152.821-1.81 1.983-1.92 3.456h3.731c.082-.539.357-.963.823-1.303.44-.312 1.043-.482 1.756-.482.796 0 1.427.198 1.92.595.467.396.714.906.714 1.558v.708h-2.798c-2.113 0-3.732.425-4.83 1.275-1.097.85-1.646 2.04-1.646 3.57 0 1.36.467 2.436 1.427 3.172.96.737 2.222 1.105 3.814 1.105 1.042 0 1.948-.198 2.716-.652.74-.424 1.372-1.048 1.893-1.898-.055 1.587.823 2.38 2.607 2.38h1.975v-3.371Zm-5.158-2.918v.283c-.027 1.02-.33 1.813-.905 2.408-.604.595-1.4.878-2.387.878-.659 0-1.153-.141-1.537-.453-.384-.283-.549-.708-.549-1.218 0-.595.22-1.048.659-1.388.439-.34 1.07-.51 1.893-.51h2.826Z" data-color="1"></path>
					        <path fill="#1E1E1E" d="M50.07 18.499c1.207-1.02 1.92-2.408 2.195-4.193h-3.978c-.165.765-.494 1.36-.988 1.785-.494.453-1.125.651-1.866.651-1.015 0-1.81-.368-2.414-1.161-.604-.765-.906-1.785-.906-3.031 0-1.247.302-2.238.906-3.032.603-.764 1.399-1.161 2.414-1.161.714 0 1.345.227 1.839.652.493.424.823.991.987 1.7h4.006C51.991 8.951 51.25 7.591 50.07 6.6c-1.207-.992-2.744-1.502-4.61-1.502-1.481 0-2.77.312-3.868.935A6.382 6.382 0 0 0 39.04 8.64c-.603 1.133-.878 2.436-.878 3.91 0 1.473.275 2.776.878 3.909a6.452 6.452 0 0 0 2.552 2.634c1.098.624 2.36.907 3.841.907 1.893 0 3.43-.482 4.637-1.501Z" data-color="1"></path>
					        <path fill="#1E1E1E" d="M54.5 8.64c-.603 1.133-.877 2.436-.877 3.938 0 1.501.302 2.804.905 3.938.604 1.133 1.482 1.983 2.634 2.578 1.153.623 2.497.906 4.088.906 1.208 0 2.278-.198 3.21-.652.933-.453 1.674-1.048 2.25-1.84a6.223 6.223 0 0 0 1.07-2.607h-3.813c-.165.68-.522 1.218-1.043 1.558-.521.34-1.18.51-1.948.51-.988 0-1.783-.283-2.332-.907-.576-.595-.906-1.416-.988-2.493v-.113H67.89c.055-.396.11-.85.11-1.36-.027-1.416-.357-2.634-.96-3.682a6.147 6.147 0 0 0-2.552-2.437c-1.097-.566-2.332-.878-3.759-.878s-2.661.312-3.731.935A6.426 6.426 0 0 0 54.5 8.64Zm9.631 2.21h-6.393c.11-.85.44-1.502 1.016-2.011.576-.51 1.29-.765 2.14-.765.878 0 1.591.255 2.167.736.576.482.933 1.162 1.07 2.04Z" data-color="1"></path>
					    </g>
					</svg>
				</a>
			</div>
			<div className="partners-item">
				<a 
					href="https://www.imoveis-sc.com.br/"
					style={{display: "table-cell"}}
					target="_blank"
					rel="noopener noreferrer"
					className="partners-image"
				>
					<img 
						width="150px"
						src="static/main/pdf/isc_logo.svg" 
						alt="imoveis-sc-logo"
					/>
				</a>
			</div>
		</div>
	)
}

Partners.displayName="Partners";