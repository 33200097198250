export const Left = () => {
	return (
		<img
			className="page-image"
			src="static/landing/devices/monitor.gif" 
			alt="on-demand" 
		/>
	)
}

Left.displayName="Left";