// App imports
import { CurrentLink } from './current';

export const SocialMediaLinks = () => {
	return (
		<div className="footer-links">
			<CurrentLink
				mediaLink="https://api.whatsapp.com/send?phone=554796350720"
				mediaImage="static/landing/footer/whatsapp.png" 
				mediaAlt="whtasapp-logo"
			/>
			<CurrentLink
				mediaLink="https://www.instagram.com/imoveissc"
				mediaImage="static/landing/footer/instagram.png" 
				mediaAlt="instagram-logo"
			/>
		</div>
	)
}

SocialMediaLinks.displayName="SocialMediaLinks";